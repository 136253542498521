<template>
  <div
    v-click-outside="hide"
    class="gen-dropdown-menu"
    :class="isOpenClass"
    @click="toggleOpenState()"
  >
    <!-- SELECTED ITEM -->
    <div
      v-if="selected"
      class="gen-dropdown-option"
    >
      <span v-if="keepStaticMenuTitle">{{ noSelectedText }}</span>
      <span
        v-else
        :style="selected.style"
        class="row-container"
      >
        <svg-icon
          v-if="selected.icon"
          class="margin margin-right margin-medium"
          :src="selected.icon"
        />
        <h6 v-if="selected.selectedTitle">
          {{ selected.selectedTitle }}
        </h6>
        <h6 v-else>
          {{ selected.title }}
        </h6>
        <svg-icon
          class="margin margin-left dropdown-chevron-icon"
          :src="chevronIcon"
        />
      </span>
    </div>

    <!-- NO SELECTED ITEM -->
    <div
      v-if="!selected"
      class="gen-dropdown-option row-container"
    >
      <svg-icon
        v-if="icon"
        class="margin margin-right margin-medium"
        :src="icon"
      />
      <h6>{{ noSelectedText }}</h6>
      <svg-icon
        class="margin margin-left dropdown-chevron-icon"
        :src="chevronIcon"
      />
    </div>
    <div class="gen-dropdown-content">
      <!-- DROPDOWN ITEMS -->
      <span
        v-for="(item, index) in items"
        :key="index"
        class="gen-dropdown-option row-container"
        :value="item"
        :style="{ ...item.style, ...getOffsetStyle(item.offset) }"
        :class="getHeaderClass(item.isHeader)"
        @click="onItemClicked(item)"
      >
        <svg-icon
          :src="item.icon"
          class="margin margin-right margin-medium"
        />
        <h6>
          {{ item.title }}
        </h6>
      </span>

      <!-- NO ITEMS ITEM -->
      <div
        v-if="!hasItems && !isLoading"
        class="gen-dropdown-option"
      >
        <span>{{ noItemsText }}</span>
      </div>

      <!-- LOADING -->
      <div
        v-if="!hasItems && isLoading"
        class="gen-dropdown-option"
      >
        <div class="spinner-small" />
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';

export default {
  name: 'DropdownMenu',
  directives: {
    ClickOutside,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    selected: {
      type: Object,
      default: () => {},
    },
    icon: {
      type: String,
      default: '',
    },
    noSelectedText: {
      type: String,
      default: '',
    },
    noItemsText: {
      type: String,
      default: '',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    keepStaticMenuTitle: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isOpen: Boolean,
    };
  },
  computed: {
    chevronIcon() {
      return (this.isOpen ? '/icons/arrow_up.svg' : '/icons/arrow_down.svg');
    },
    isOpenClass() {
      return (this.isOpen ? 'open' : 'closed');
    },
    hasItems() {
      return (!!this.items && this.items.length > 0);
    },
  },
  mounted() {
    this.isOpen = false;
  },
  methods: {
    toggleOpenState() {
      this.isOpen = !this.isOpen;
    },
    hide() {
      this.isOpen = false;
    },
    getNumber(target) {
      if (target && typeof target === 'number') {
        return target;
      }
      return 0;
    },
    getOffsetStyle(offset) {
      return `padding-left: ${16 + (16 * this.getNumber(offset))}px`;
    },
    getHeaderClass(isHeader) {
      return (isHeader ? 'gen-dropdown-menu-header' : '');
    },
    onItemClicked(item) {
      this.$emit('onItemClicked', item);
    },
  },
};
</script>

<style scoped>

.gen-dropdown-menu {
  display: inline-block;
  position: relative;
  background-color: var(--bg-color);
  border-style: solid;
  border-width: 1px;
  border-color: var(--bg-secondary-detail-color);
}

.gen-dropdown-menu:hover {
  cursor: pointer;
  border-color: var(--bg-primary-detail-color);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.05);
}

.gen-dropdown-content {
  display: none;
  position: absolute;
  top: calc(100% + 1px);
  min-width: 160px;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.gen-dropdown-option {
  display: flex;
  position: relative;
  padding: 8px 16px;
  background-color: var(--bg-color);
  color: var(--primary-font-color);
  font-size: 0.8rem;
  font-weight: 500;
}

.dropdown-chevron-icon {
  pointer-events: none;
}

span {
  display: flex;
  white-space: nowrap;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                   supported by Chrome, Edge, Opera and Firefox */
}

.gen-dropdown-content .gen-dropdown-option:hover {
  background-color: var(--bg-secondary-detail-color);
}

.open .gen-dropdown-content {
  display: block;
}

.row-container * {
  margin-top: auto;
  margin-bottom: auto;
}
</style>
