<template>
  <div>
    <Header
      :back="true"
      :route="this.$parent.$data.backRoute.path"
      class="flex"
    >
      <h5 class="header-title">
        {{ $t('Components.Admin.Issue_Reports.issues_pinned') }}
      </h5>
    </Header>
    <Card position="bottom">
      <IssueListItem
        v-for="issue in pinnedIssues"
        :key="issue.id"
        :issue="issue"
        :is-in-list-view="true"
        class="margin margin-bottom margin-medium-large"
      />
      <div v-if="!pinnedIssues.length">
        <p>
          {{ $t('Components.Admin.Issue_Reports.issue_nothing_to_display') }}
        </p>
      </div>
    </Card>
  </div>
</template>

<script>
/* Components */
import Card from '@/components/card/card.vue';
import IssueListItem from '@/views/admin/issue/components/issue.list.item.vue';
import Header from '@/components/headers/header.vue';

/* States */
import IssueState from '@/singletons/issue.state.singleton';

/* Mixins */
import QueryMixin from '../../../mixins/query.mixin';

export default {
  components: {
    IssueListItem,
    Card,
    Header,
  },
  mixins: [QueryMixin],
  data() {
    return {
      IssueState,
    };
  },
  computed: {
    pinnedIssues() {
      const pinnedIssues = Object.values(IssueState.pinnedIssues);
      return IssueState.getIssuesSortedByStatus(pinnedIssues);
    },
  },
  mounted() {
    // We do this since the datepicker decorates the URL with date when we refresh the page
    this.removeQuery('date');
  },
};
</script>

<style lang="scss">
</style>
