<template>
  <div>
    <div v-if="this.$route.name === 'admin-issue-tracker'">
      <Header
        :back="true"
        :route="'/admin'"
        :text="$t('Components.Sidebar.Admin')"
        class="flex"
        :title="$t('Views.Sensors.sensors')"
      >
        <h5 class="header-title">
          {{ $t('Components.Admin.Issue_Reports.issues_overview') }}
        </h5>
        <div class="pinned-issues-button">
          <router-link
            :to="{name: 'admin-pinned-issues'}"
            class="pinned-issues"
          >
            <p class="center-text">
              Pinned
            </p>
            <div class="forward-icon">
              <icon
                icon="chevron-right"
                class="wdp__chevron"
              />
            </div>
          </router-link>
        </div>
      </Header>
      <div class="scrollable sub">
        <Card>
          <WeeklyDateSelectorReversed
            :week-length="WeekState.weekLength"
            :week-start="WeekState.date"
            :notifications="notifications"
            @date-selected="dateChanged"
            @week-changed="weekChanged"
          />
        </Card>
        <Card position="bottom">
          <IssueListItem
            v-for="issue in dailyIssues"
            :key="issue.id"
            :issue="issue"
            :is-in-list-view="true"
            class="margin margin-bottom margin-medium-large"
          />
          <div v-if="!dailyIssues.length">
            <p>
              {{ $t('Components.Admin.Issue_Reports.issue_nothing_to_display') }}
            </p>
          </div>
        </Card>
      </div>
    </div>
    <div>
      <router-view />
    </div>
  </div>
</template>
<script>
/* States */
import WeekState from '@/singletons/week.state.singleton';
import CompanyState from '@/singletons/company.state.singleton';
import IssueState from '@/singletons/issue.state.singleton';
import MapState from '@/singletons/map.state.singleton';

/* Components */
import Card from '@/components/card/card.vue';
import Header from '@/components/headers/header.vue';
import WeeklyDateSelectorReversed from '@/components/weeklydateselector/weekly.dateselector.reversed.vue';
import IssueListItem from '@/views/admin/issue/components/issue.list.item.vue';

/* Services */
import IssueService from '@/Services/Issue/issue.service';

/* Classes */
import ExtendedDate from '@/classes/extended.date.class';

/* Controllers */
import MapController from '@/classes/map/map.controller';

/* Functions */
import { getIssueNotifications } from '@/functions/date.notification.functions';
import SearchManager from '@/Services/Search/SearchManager';
import MapIssueController from '../../../classes/map/map.issue.controller';

/* Mixins */
import QueryMixin from '../../../mixins/query.mixin';

export default {
  components: {
    Card,
    Header,
    WeeklyDateSelectorReversed,
    IssueListItem,
  },
  mixins: [QueryMixin],
  data() {
    return {
      WeekState,
      notifications: [],
      isLoading: true,
      IssueState,
      viewingPinnedIssues: false,
      backRoute: { path: '/admin/issue-tracker', text: this.$t('Components.Admin.Issue_Reports.issues_overview') },
    };
  },
  computed: {
    issues() {
      return IssueState.getIssuesSortedByStatus();
    },
    weeklyIssues() {
      const weeklyIssues = this.issues.filter((issue) => WeekState.weekStart.getDate() <= new Date(issue.createdat).getDate() <= WeekState.weekEnd.getDate());
      return IssueState.getIssuesSortedByStatus(weeklyIssues);
    },
    dailyIssues() {
      const dailyIssues = this.weeklyIssues.filter((issue) => new ExtendedDate(issue.createdat).getDateString() === WeekState.date.getDateString());
      return IssueState.getIssuesSortedByStatus(dailyIssues);
    },
    pinnedIssues() {
      return Object.values(IssueState.pinnedIssues);
    },
  },
  watch: {
    async $route(to, from) {
      await MapState.hasFinnishedLoadingPromise;
      await this.updateMapOnRoute(to, from);

      // If from specific -> specific => MAIN
      if ((from?.name === 'admin-specific-issue' && to.name === 'admin-specific-issue') || to.name === this.backRoute.name) {
        this.backRoute = { path: '/admin/issue-tracker', text: this.$t('Components.Admin.Issue_Reports.issues_overview') };
      } else {
        this.backRoute = from;
      }

      if (this.$route.name === 'admin-pinned-issues') {
        this.viewingPinnedIssues = true;
      }

      if (this.$route.name === 'admin-issue-tracker') {
        this.viewingPinnedIssues = false;
        this.updateNotifications();
        this.replaceQuery({ date: WeekState.date.getDateString() });
      }
    },
  },
  async created() {
    MapIssueController.init();

    const { date } = this.$route.query;
    const checkedDate = date && date !== 'undefined' ? new ExtendedDate(date) : new ExtendedDate();
    await this.weekChanged(checkedDate);
    await IssueService.getPinnedIssues(CompanyState.cid);
    await this.updateMapOnRoute(this.$route);
    await SearchManager.getColleagues();

    MapState.mode = MapState.MODE_TYPE.issue;
    if (MapController.hasInitialized && MapState.cameraViewType !== MapState.CAMERA_VIEW_TYPE.overview && !this.isLoading) {
      MapController.setCameraView(MapState.CAMERA_VIEW_TYPE.overview);
    }

    this.$root.$on('onFloorChanged', this.onFloorplanChanged);
    this.attachMapEventListeners();
    this.isLoading = false;
  },
  beforeDestroy() {
    // Unsubscribe from onFloorChanged
    this.$root.$off('onFloorChanged', this.onFloorplanChanged);
  },
  destroyed() {
    MapIssueController.clear();
    MapState.mode = MapState.MODE_TYPE.book;
  },
  methods: {
    onFloorplanChanged() {
      // Have to setup listeners again because they were destroyed on changing floor
      // Important to unsubscribe $root events, or it will double each time a floor is changed
      this.attachMapEventListeners();
      this.resetIssueMarkers();
    },
    onIssueSelected(selectedObj) {
      const issues = Object.values(selectedObj.userData.issues);
      if (issues.length > 1) {
        this.$router.push({
          name: 'admin-resource-specific-issues',
          params: { zoneid: issues[0].zoneid },
          query: { map: true },
        });
      } else {
        const routeData = {
          name: 'admin-specific-issue',
          params: { issueid: issues[0].id },
          query: { map: true },
        };
        if (this.$route.name === 'admin-specific-issue') {
          this.$router.replace(routeData);
        } else {
          this.$router.push(routeData);
        }
      }
    },
    onIssueDeselected() {
      if (this.backRoute && this.backRoute.query) {
        this.backRoute.query.map = true;
      }
      this.$router.push(this.backRoute);
    },
    attachMapEventListeners() {
      MapController.selectionManager.onIssueSelected(this.onIssueSelected);
      MapController.selectionManager.onIssueDeselected(this.onIssueDeselected);
    },
    async updateMapOnRoute(to, from) {
      const allowCameraFocusIssue = !to.query.map;
      const allowCameraFocusMap = !to.query.map && !from?.query.map;

      MapIssueController.deselectIssueMarker();

      if (to.name === 'admin-specific-issue') {
        const { issueid } = to.params;
        const issue = IssueState.issues[issueid] || IssueState.pinnedIssues[issueid] || await IssueService.getIssue(issueid);
        MapIssueController.addIssueMarkers({ issueid: issue }, () => {
          MapIssueController.selectIssueMarkerInZone(issue.zoneid, allowCameraFocusIssue);
        }, true);
      } else if (to.name === 'admin-resource-specific-issues') {
        const { zoneid } = to.params;
        const issuesToSort = this.viewingPinnedIssues
          ? IssueState.pinnedIssues
          : this.dailyIssues;
        const zoneIssues = IssueState.getIssuesByZoneId(issuesToSort)[zoneid];
        if (zoneIssues) {
          MapIssueController.addIssueMarkers(Object.values(zoneIssues).filter((issue) => issue.status !== 'CLOSED'), () => {
            MapIssueController.selectIssueMarkerInZone(zoneid, allowCameraFocusIssue);
          }, true);
        }
      } else if (to.name === 'admin-pinned-issues') {
        MapIssueController.addIssueMarkers(IssueState.pinnedIssues);

        if (allowCameraFocusMap) {
          MapController.panAndZoomOut();
        }
      } else if (to.name === 'admin-issue-tracker') {
        MapIssueController.addIssueMarkers(this.dailyIssues.filter((issue) => issue.status !== 'CLOSED'));

        if (from?.name === 'admin-pinned-issues') {
          this.resetIssueMarkers();
          if (allowCameraFocusMap) {
            MapController.panAndZoomOut();
          }
        } else if (from?.name === 'admin-resource-specific-issues') {
          MapIssueController.addIssueMarkers(this.dailyIssues.filter((issue) => issue.status !== 'CLOSED'));
        }

        if (from?.name !== 'admin-issue-tracker' && allowCameraFocusMap) {
          MapController.panAndZoomOut();
        }
      }
    },
    async fetchIssues() {
      await IssueService.getIssues(
        CompanyState.cid,
        WeekState.weekStart.getDateString(),
        WeekState.weekEnd.getDateString(),
      );

      this.updateNotifications();
      this.resetIssueMarkers(this.dailyIssues);
    },
    resetIssueMarkers(issues = this.dailyIssues) {
      MapController.deselectMapLocation();
      MapIssueController.deleteIssueMarkers();
      MapIssueController.addIssueMarkers(issues.filter((issue) => issue.status !== 'CLOSED'));
    },
    async weekChanged(date) {
      WeekState.changeWeek(date);
      this.replaceQuery({ date: WeekState.date.getDateString() });
      await this.fetchIssues();
      this.updateNotifications();
    },
    async dateChanged(date) {
      WeekState.date = date;
      this.replaceQuery({ date: WeekState.date.getDateString() });
      this.updateNotifications();
    },
    updateNotifications() {
      this.notifications = getIssueNotifications(this.weeklyIssues);
    },
  },
};
</script>

<style lang="scss">
.scrollable-height-vh {
  height: 100vh;
}
.pinned-issues-button{
  align-self: center;
  justify-self: end;
  overflow: hidden;
  max-width: 100%;
  a {
    display: flex;
    cursor: pointer;
    align-self: center;
    justify-self: end;
    .forward-icon {
      display: flex;
      width: 24px;
      height: 24px;
      .wdp__chevron {
        margin: auto;
        color: white;
      }
    }
    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: white;
      margin-bottom: unset;
    }
  }
}
</style>
