import { issueStatusSortingOrder } from '../classes/issue/issue';

class IssueState {
  constructor() {
    this.issues = {};
    this.pinnedIssues = {};
    this.issueStatuses = [];
  }

  destroy() {
    this.issues = {};
    this.pinnedIssues = {};
    this.issueStatuses = [];
  }

  getIssuesByZoneId(issues = this.issues) {
    const issuesByZonesId = {};

    Object.values(issues).forEach((issue) => {
      if (issuesByZonesId[issue.zoneid]) {
        issuesByZonesId[issue.zoneid][issue.id] = issue;
      } else {
        issuesByZonesId[issue.zoneid] = {};
        issuesByZonesId[issue.zoneid][issue.id] = issue;
      }
    });

    return issuesByZonesId;
  }

  getIssuesSortedByStatus(issues = this.issues) {
    return Object.values(issues).sort((A, B) => issueStatusSortingOrder.indexOf(A.status) - issueStatusSortingOrder.indexOf(B.status));
  }
}

export default new IssueState();
