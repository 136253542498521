<template>
  <div>
    <Header
      :back="true"
      :route="this.$parent.$data.backRoute.path"
      class="flex"
    >
      <h5 class="header-title">
        {{ resource?.Name || $t('Views.NotFound.oops') }}
      </h5>
      <div class="header-actions-wrapper">
        <div
          class="primary-color-icon map-pin-icon"
          @click="routeToResourceTiedIssues()"
        >
          <svg-icon
            src="/icons/map_navigation_pin.svg"
          />
        </div>
      </div>
    </Header>
    <div class="scrollable">
      <Card
        position="bottom"
      >
        <h6 class="margin margin-bottom margin-medium">
          {{ $t('Components.Admin.Issue_Reports.issues_all_issues_for_resource') }}
        </h6>
        <IssueListItem
          v-for="issue in issues"
          :key="issue.id"
          :issue="issue"
          :is-in-list-view="true"
          class="margin margin-bottom margin-medium-large"
        />
        <div v-if="!issues.length">
          <p>
            {{ $t('Components.Admin.Issue_Reports.issue_nothing_to_display') }}
          </p>
        </div>
      </Card>
    </div>
  </div>
</template>

<script>

/* Components */
import Card from '@/components/card/card.vue';
import Header from '@/components/headers/header.vue';
import IssueListItem from '@/views/admin/issue/components/issue.list.item.vue';

/* States */
import ZoneState from '@/singletons/zone.state.singleton';
import IssueState from '@/singletons/issue.state.singleton';
import MapIssueController from '../../../classes/map/map.issue.controller';

/* Mixins */
import QueryMixin from '../../../mixins/query.mixin';

export default {
  components: {
    Card,
    Header,
    IssueListItem,
  },
  mixins: [QueryMixin],
  data() {
    return {
      zoneid: null,
      issueid: null,
      resource: {},
    };
  },
  computed: {
    issues() {
      const issuesToFilter = this.$parent.$data.viewingPinnedIssues ? IssueState.pinnedIssues : this.$parent.dailyIssues;
      const issuesForResource = Object.values(issuesToFilter).filter((issue) => issue.zoneid === this.zoneid && issue.status !== 'CLOSED');
      return IssueState.getIssuesSortedByStatus(issuesForResource);
    },
  },
  watch: {
    // Call the method if the route changes
    $route: {
      handler: 'refresh',
      immediate: true, // runs immediately with mount() instead of calling method on mount hook
    },
  },
  created() {
    this.refresh();
  },
  mounted() {
    // We do this since the datepicker decorates the URL with date when we refresh the page
    this.removeQuery('date');
  },
  methods: {
    refresh() {
      this.zoneid = Number(this.$route.params.zoneid);
      this.issueid = Number(this.$route.query.issueid);
      this.resource = ZoneState.activeResources.find((zone) => zone.Zid === this.zoneid);
    },
    async routeToResourceTiedIssues() {
      MapIssueController.selectIssueMarkerInZone(this.zoneid, true);
    },
  },
};
</script>

<style lang="scss">
.header-actions-wrapper {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 3rem;
  .map-pin-icon, .issue-pin-icon {
    display: inline-block;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    cursor: pointer;
    svg {
      path {
        fill: white;
      }
    }
  }
}
</style>
