/* eslint-disable no-return-assign */
import IssueState from '@/singletons/issue.state.singleton';
import {
  getIssue,
  getIssues,
  getIssueStatuses,
  getPinnedIssues,
  updateIssue,
  createIssue,
  removeIssue,
  assignIssue,
  unassignIssue,
} from '../../util/api';

class IssueService {
  /* GET */
  static async getIssue(issueid) {
    const { data } = await getIssue(issueid);
    return IssueState.issues[data.id] = data;
  }

  static async getIssues(companyid, from, until, getdeleted = false) {
    const { data } = await getIssues(companyid, from, until, getdeleted);
    const mappedIssues = {};
    // Map issues to a list with id as key
    data.forEach((issue) => {
      mappedIssues[issue.id] = issue;
    });
    return IssueState.issues = mappedIssues;
  }

  static async getIssueStatuses() {
    const { data } = await getIssueStatuses();
    return IssueState.issueStatuses = data;
  }

  static async getPinnedIssues(companyid) {
    const { data } = await getPinnedIssues(companyid);
    const mappedIssues = {};
    // Map issues to a list with id as key
    data.forEach((issue) => {
      mappedIssues[issue.id] = issue;
    });
    return IssueState.pinnedIssues = mappedIssues;
  }

  /* CREATE */

  static async createIssue(issue, sendGrid) {
    const { data } = await createIssue({ partialIssue: issue.toPartial(), sendGrid });
    return data;
  }

  static async assignIssue(issueid, userid) {
    const { data } = await assignIssue(issueid, userid);
    if (data) {
      IssueState.issues[issueid].pushAssignee(userid);
    }

    return data;
  }

  /* UPDATE */

  static async updateIssue(issueid, issue) {
    const { data } = await updateIssue(issueid, issue.toPartial());
    // Update issue in lists if it exists there
    if (IssueState.issues[data.id]) {
      IssueState.issues[data.id].status = data && data.status;
    }
    if (data.pinned) {
      IssueState.pinnedIssues[data.id] = data;
    } else if (!data.pinned && IssueState.pinnedIssues[data.id]) {
      delete IssueState.pinnedIssues[data.id];
    }

    return data;
  }

  /* DELETE */

  static async deleteIssue(issueid) {
    await removeIssue(issueid);
    delete IssueState.issues[issueid];
  }

  static async unassignIssue(issueid, userid) {
    const { data } = await unassignIssue(issueid, userid);
    if (data) {
      IssueState.issues[issueid].deleteAssignee(userid);
    }
  }
}

export default IssueService;
