import SpriteText from 'three-spritetext';
import IssueState from '@/singletons/issue.state.singleton';
import * as THREE from 'three';
import MapState from '@/singletons/map.state.singleton';
import AnimationHelper from './animation.helper';
import MapController from './map.controller';

class MapIssueController {
  constructor() {
    this.hasInitialized = false;
  }

  init = () => {
    if (this.hasInitialized) {
      return;
    }
    MapState.issueTextures.OPEN.default = new THREE.TextureLoader().load('/icons/map-icons/issue_bubble_red.svg');
    MapState.issueTextures.IN_PROGRESS.default = new THREE.TextureLoader().load('/icons/map-icons/issue_bubble_amber.svg');
    MapState.issueTextures.RESOLVED.default = new THREE.TextureLoader().load('/icons/map-icons/issue_bubble_green.svg');
    MapState.issueTextures.CLOSED.default = new THREE.TextureLoader().load('/icons/map-icons/issue_bubble_gray.svg');

    MapState.issueTextures.OPEN.notification = new THREE.TextureLoader().load('/icons/map-icons/issue_bubble_notification_red.svg');
    MapState.issueTextures.IN_PROGRESS.notification = new THREE.TextureLoader().load('/icons/map-icons/issue_bubble_notification_amber.svg');
    MapState.issueTextures.RESOLVED.notification = new THREE.TextureLoader().load('/icons/map-icons/issue_bubble_notification_green.svg');
    MapState.issueTextures.CLOSED.notification = new THREE.TextureLoader().load('/icons/map-icons/issue_bubble_notification_gray.svg');

    this.hasInitialized = true;
  }

  clear = () => {
    this.deleteIssueMarkers();
    MapController.selectionManager.eventListeners.on_issue_selected = [];
    MapController.selectionManager.eventListeners.on_issue_deselected = [];

    MapState.issueTextures.OPEN.default.dispose();
    MapState.issueTextures.IN_PROGRESS.default.dispose();
    MapState.issueTextures.RESOLVED.default.dispose();
    MapState.issueTextures.CLOSED.default.dispose();

    MapState.issueTextures.OPEN.notification.dispose();
    MapState.issueTextures.IN_PROGRESS.notification.dispose();
    MapState.issueTextures.RESOLVED.notification.dispose();
    MapState.issueTextures.CLOSED.notification.dispose();

    this.hasInitialized = false;
  }

  selectIssueMarkerInZone = (zoneid, panAndZoom = false) => {
    this.deselectIssueMarker();
    MapState.selectedObj = MapState.markerObjects.issueMarkers[zoneid];
    if (!MapState.selectedObj) {
      return;
    }
    AnimationHelper.pulse(MapState.selectedObj);
    if (panAndZoom) {
      MapController.panAndZoomToPosition(MapState.selectedObj.position, 8);
    }
    MapController.queueRender();
  }

  deselectIssueMarker = () => {
    if (MapState.selectedObj) {
      AnimationHelper.stopAnimating(MapState.selectedObj);
    }
    MapState.selectedObj = null;
  }

  updateIssueMarker = (zoneid, issues) => {
    const issueMarker = MapState.markerObjects.issueMarkers[zoneid];
    if (!issueMarker) {
      return;
    }
    const issueMarkerSprite = issueMarker.children[0];
    let issueTextSprite = issueMarkerSprite.children[0];

    const issuesByStatus = IssueState.getIssuesSortedByStatus(issues);

    if (issues.length > 1) {
      if (!issueTextSprite) {
        issueTextSprite = new SpriteText('', 0.2, 'white');
        issueTextSprite.renderOrder = issueMarkerSprite;
        issueTextSprite.center.add(new THREE.Vector2(-2.85, -4.1));
        issueMarkerSprite.add(issueTextSprite);
      }
      issueMarkerSprite.material.map = MapState.issueTextures[issuesByStatus[0].status].notification;
      issueTextSprite.text = issues.length >= 10 ? '?' : `${issues.length}`;
    } else {
      if (issueTextSprite) {
        issueMarkerSprite.remove(issueTextSprite);
      }
      issueMarkerSprite.material.map = MapState.issueTextures[issuesByStatus[0].status].default;
    }
  }

  addIssueMarker = (zoneid, issues, callback) => {
    this.deleteIssueMarker(zoneid);

    if (!MapState.floorplanGroup?.children) {
      return;
    }
    const zoneGroup = MapController.selectionManager.findChildGroup(
      MapState.floorplanGroup.children,
      zoneid,
      (MapState?.selectedZone?.Type === 'LOCKER'),
    );

    if (!zoneGroup) {
      return;
    }

    const vector = MapController.selectionManager.getVectorCoordinatesFromGroup(zoneGroup);

    const issueMarkerSprite = new THREE.Sprite(new THREE.SpriteMaterial({ depthTest: false }));
    issueMarkerSprite.renderOrder = MapState.RENDER_ORDERS.sprite;
    // Shift the sprite upward to get a better animation anchor
    issueMarkerSprite.center.add(new THREE.Vector2(0, -0.5));

    const issuesByStatus = IssueState.getIssuesSortedByStatus(issues);

    const issueCount = Object.keys(issues).length;
    if (issueCount > 1) {
      issueMarkerSprite.material.map = MapState.issueTextures[issuesByStatus[0].status].notification;
      const issueCountText = issueCount >= 10 ? '?' : issueCount;
      const issueTextSprite = new SpriteText(`${issueCountText}`, 0.2, 'white');
      issueTextSprite.renderOrder = issueMarkerSprite;
      issueTextSprite.center.add(new THREE.Vector2(-2.85, -4.1));
      issueMarkerSprite.add(issueTextSprite);
    } else {
      issueMarkerSprite.material.map = MapState.issueTextures[issuesByStatus[0].status].default;
    }

    const markerGroup = new THREE.Group();
    markerGroup.position.set(vector.x, vector.y - 0.5, vector.z);
    markerGroup.add(issueMarkerSprite);
    markerGroup.userData.clickable = true;
    markerGroup.userData.issues = JSON.parse(JSON.stringify(issues));
    markerGroup.prev_position = { ...markerGroup.position };
    markerGroup.prev_scale = { ...markerGroup.scale };

    MapState.markerObjects.issueMarkers[zoneid] = markerGroup;
    MapState.scene.add(markerGroup);
    AnimationHelper.downAndFadeIn(issueMarkerSprite, Math.random() * 400, () => {
      if (callback instanceof Function) {
        callback(zoneid);
      }
    });

    MapController.queueRender();
  }

  addIssueMarkers = (issues, callback, additive = false) => {
    const issuesByZoneId = IssueState.getIssuesByZoneId(issues);

    if (!additive) {
      const zoneIds = Object.keys(issuesByZoneId);
      Object.keys(MapState.markerObjects.issueMarkers).forEach((zoneid) => {
        // Clear markers that aren't in our list
        if (!zoneIds.includes(zoneid)) {
          this.deleteIssueMarker(zoneid);
        }
      });
    }

    Object.entries(issuesByZoneId).forEach(([zoneid, zoneIssues]) => {
      const existingZoneMarker = MapState.markerObjects.issueMarkers[zoneid];
      if (existingZoneMarker && Object.keys(existingZoneMarker.userData.issues).length === Object.keys(zoneIssues).length) {
        Object.values(existingZoneMarker.userData.issues).every((markerIssue) => {
          if (!zoneIssues[markerIssue.id] || zoneIssues[markerIssue.id].status !== markerIssue.status) {
            // If issues failed the comparison, add new issue marker
            this.addIssueMarker(Number(zoneid), zoneIssues, callback);
            return false;
          }
          return true;
        });
      } else {
        this.addIssueMarker(Number(zoneid), zoneIssues, callback);
        return;
      }

      if (callback instanceof Function) {
        callback(zoneid);
      }
    });
  }

  deleteIssueMarker = (zoneid) => {
    if (MapState.markerObjects.issueMarkers[zoneid]) {
      MapState.scene.remove(MapState.markerObjects.issueMarkers[zoneid]);
      delete MapState.markerObjects.issueMarkers[zoneid];
    }
  }

  deleteIssueMarkers = () => {
    Object.values(MapState.markerObjects.issueMarkers).forEach((marker) => {
      MapState.scene.remove(marker);
    });

    MapState.markerObjects.issueMarkers = {};
  }
}

export default new MapIssueController();
