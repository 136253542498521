<template>
  <div
    v-if="week.length"
    class="wdp__wrapper"
  >
    <div class="wdp__header__wrapper">
      <div>
        <h6
          v-if="!weekBetweenMonth"
          class="wdp__header normal-semi noselect"
        >
          {{ getWeekStart }} - {{ getWeekEnd }} {{ getMonth }} {{ getYear }}
        </h6>
        <h6
          v-else
          class="wdp__header normal-semi noselect"
        >
          {{ getWeekStart }} {{ getMonthForStartOfWeek }} - {{ getWeekEnd }} {{ getMonth }} {{ getYear }}
        </h6>
        <p class="small small-medium">
          {{ $t('Components.BackToOffice.week') }} {{ getWeekNumber }}
        </p>
      </div>
      <div class="wbp_action__wrapper">
        <div>
          <div
            class="wbp_today__wrapper"
            :class="{'disabled': selectedDate.getDateString() === today.getDateString()}"
            @click="setDayToToday()"
          >
            <h6>
              {{ $t('Components.BackToOffice.today') }}
            </h6>
          </div>
        </div>
        <div class="wdp__chevrons-wrapper">
          <div
            class="wdp__chevron-wrapper"
            @click="switchWeek('decrement')"
          >
            <icon
              icon="chevron-left"
              class="wdp__chevron"
            />
          </div>
          <div
            class="wdp__chevron-wrapper noselect"
            :class="{'isDisabled': !canNavigateToNextWeek}"
            @click="switchWeek('increment')"
          >
            <icon
              icon="chevron-right"
              class="wdp__chevron"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      class="wdp__day-options--flex-container"
    >
      <div
        v-if="week !== null"
        class="wdp__dates-holder--wrapper"
      >
        <div
          v-for="(day,index) in week"
          :key="index"
          class="wdp__date-option--wrapper noselect"
          :class="{'disabled': !isDaySelectable(day)}"
          @click="changeDay(day, true)"
        >
          <div
            v-if="isDaySelectable(day)"
            class="wdp__notfication-container"
          >
            <div
              v-for="(notification, indx) in notifications[index]"
              :key="indx"
              :class="notification ? `wdp__issue-notification ${notification}`: '' "
              :style="{'background-color': notification}"
            />
          </div>
          <div class="wdp__day-container">
            <h6 class="secondary normal-medium">
              {{ day.weekDay('short')[0] }}
            </h6>
          </div>
          <div class="wdp__date-container">
            <h5 class="x-big-semi">
              {{ day.getDate() }}
            </h5>
          </div>
          <div
            class="wdp__day-selected--mark"
            :class="{'selected': selectedDate.getDate() === day.getDate(),
                     'single-digit-day': singleDigit,
            }"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ExtendedDate from '@/classes/extended.date.class';

export default {
  props: {
    weekStart: {
      type: Date,
      required: true,
    },
    weekLength: {
      type: Number,
      required: true,
    },
    notifications: {
      type: Array,
      default: () => [],
      required: false,
    },
  },
  data() {
    return {
      week: [],
      currentWeek: null,
      weekObject: null,
      selectedDate: null,
      today: null,
    };
  },
  computed: {
    getMonth() { return new ExtendedDate(this.selectedDate).getMondayAlt().add('days', this.weekLength - 1).monthName('short'); },
    getYear() { return this.selectedDate.getFullYear(); },
    getWeekStart() { return new ExtendedDate(this.selectedDate).getMondayAlt().getDate(); },
    getWeekEnd() { return new ExtendedDate(this.selectedDate).getMondayAlt().add('days', this.weekLength - 1).getDate(); },
    getWeekNumber() { return this.selectedDate.getWeekNumber(); },
    canNavigateToPreviousWeek() {
      const previousWeekDay = new ExtendedDate(this.selectedDate.getMondayAlt().add('days', -1));
      return (previousWeekDay.getTime() > this.today.getTime());
    },
    canNavigateToNextWeek() {
      const futureWeekDay = new ExtendedDate(this.selectedDate.getMondayAlt().add('days', +6));
      return (futureWeekDay.getTime() < this.today.getTime());
    },
    singleDigit() {
      if (this.selectedDate.getDate().toString().length > 1) {
        return false;
      }
      return true;
    },
    weekBetweenMonth() {
      return (new ExtendedDate(this.week[0]).getMonth() !== new ExtendedDate(this.week[4]).getMonth());
    },
    getMonthForStartOfWeek() {
      return new ExtendedDate(this.week[0]).monthName('short');
    },
  },
  mounted() {
    this.today = new ExtendedDate();
    this.today.setHours(0, 0, 0, 0);
    this.selectedDate = this.weekStart;
    this.currentWeek = this.getWeekNumber;
    this.createWeek(this.weekStart.getMondayAlt());
  },
  methods: {
    createWeek(weekStart = new ExtendedDate().getMondayAlt()) {
      this.week = [];
      for (let i = 0; i < this.weekLength; i += 1) {
        this.week.push(new ExtendedDate(weekStart).add(('days'), i));
      }
    },
    switchWeek(arg) {
      if (!this.canNavigateToNextWeek && arg === 'increment') {
        return;
      }
      const dateOffset = arg === 'decrement' ? -7 : 7;
      const weekStart = new ExtendedDate(this.selectedDate.add('days', dateOffset)).getMondayAlt();
      // If we are on current week return today and the day is selectable
      this.selectedDate = (this.currentWeek === weekStart.getWeekNumber() || !this.isDaySelectable(weekStart)) ? this.today : weekStart;
      this.createWeek(weekStart);
      this.changeDay(weekStart, false);
      this.$emit('week-changed', this.selectedDate);
    },
    goToStartWeek() {
      this.createWeek(new ExtendedDate().getMondayAlt());
      this.changeDay(new ExtendedDate(), true);
      this.$emit('week-changed', new ExtendedDate());
    },
    setDayToToday() {
      this.goToStartWeek();
    },
    changeDay(day, emit) {
      if (!this.isDaySelectable(day)) { return; }
      this.selectedDate = day;
      if (emit) this.$emit('date-selected', day);
    },
    isDaySelectable(day) {
      const today = new ExtendedDate();
      today.setHours(23, 59, 0, 0);
      return (day.getTime() <= today.getTime());
    },
  },
};

</script>

<style lang="scss" scoped>

@import '@/scss/breakpoints.scss';

.wdp__wrapper{
  margin: 12px 0;
}

.wdp__header {
  color: var(--primary-font-color);
  margin: auto 0;
}

.wdp__header__wrapper{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 32px;
  p{
    margin: 0;
  }
}

.wdp__dates-holder--wrapper {
  display: flex;
  justify-content: space-between;
}
.wbp_action__wrapper{
  gap: 1rem;
  display: flex;
  justify-content: flex-end;
}
.wbp_today__wrapper{
  height: 2rem;
  border: 1px solid var(--border-color);
  border-radius: 0.5rem;
  padding:  0.2rem 1rem;
  display: flex;
  &:hover{
    cursor: pointer;
  }
  &.disabled {
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
  }
  h6{
  color: var(--primary-font-color);
  margin: auto 0;
  }
}

.wdp__chevrons-wrapper{
  display: flex;
  justify-content: space-between;
  gap: 0.3rem;
  svg{
    width: 100%;
  }
}
.wdp__chevron-wrapper {
  color: var(--primary-font-color);
  border: 1px solid var(--border-color);
  padding: 0.2rem;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  &:hover {
    cursor: pointer;
  }
  &.isDisabled {
    opacity: 0.5;
    cursor: default;
  }
}
.wdp__date-option--wrapper {
  position: relative;
  padding: 0 0.5rem 0 0.5rem;

  &:hover {
    cursor: pointer;
  }

  &.disabled {
    h5, h6, h4 {
      opacity: 0.4;
    }
    &:hover {
      cursor: default;
    }
  }

  h5, h6, h4 {
    margin: 0;
    font-variant-numeric: tabular-nums;
    text-align: center;
    opacity: 1;
  }
}

.wdp__day-selected--mark {
  height: 0;
  width: 2rem;
  background-color: var(--primary-color);
  position: absolute;
  &.selected.single-digit-day {
    transform: translateX(-0.52rem);
    height: 0.4rem;
  }
  &.selected {
    transform: translateX(-0.1rem);
    height: 0.4rem;
  }
}

.wdp__notfication-container{
    display: flex;
    justify-content: space-between;
    gap: 0.2rem;
    position:absolute;
    transform: translateX(1.5rem) translateY(-0.5rem);
  .wdp__issue-notification {
    height: 10px;
    width: 10px;
    transition: ease width, height 0.2s;
    border-radius: 50%;
}

}
</style>
