<template>
  <div>
    <Header
      :back="true"
      :route="this.$parent.$data.backRoute.path"
      class="flex"
    >
      <h5 class="header-title">
        {{ resource?.Name || $t('Views.NotFound.oops') }}
      </h5>
      <div class="header-actions-wrapper">
        <div
          class="map-pin-icon"
          @click="$refs.issueListItem.routeToResourceTiedIssues()"
        >
          <svg-icon
            src="/icons/map_navigation_pin.svg"
          />
        </div>
        <div
          class="issue-pin-icon"
          @click="togglePinnedIssue()"
        >
          <svg-icon :src="pinIconSrc" />
        </div>
      </div>
    </Header>
    <div>
      <div v-if="!$parent.$data.isLoading && issue">
        <IssueListItem
          ref="issueListItem"
          :issue="issue"
          :is-in-list-view="false"
        />
        <Card position="bottom">
          <div class="status-wrapper">
            <h6 class="normal-semi issue-status">
              {{ $t('Components.Admin.Issue_Reports.issue_status') }}
            </h6>
            <div class="issue-set-new-status">
              <div>
                <DropDownMenu
                  :items="availableStatusList"
                  :selected="selected"
                  :icon="selected?.icon"
                  :no-items-text="$t('Components.Admin.Issue_Reports.issue_status_no_list_options')"
                  :keep-static-menu-title="false"
                  @onItemClicked="onDropdownItemClicked"
                />
              </div>
            </div>
          </div>
        </Card>
      </div>
      <div v-else>
        <p>
          {{ $t('Components.Admin.Issue_Reports.issue_nothing_to_display') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>

/* Components */
import Header from '@/components/headers/header.vue';
import IssueListItem from '@/views/admin/issue/components/issue.list.item.vue';
import DropDownMenu from '@/components/DropdownMenu/DropdownMenu.vue';
import Card from '@/components/card/card.vue';

/* States */
import IssueState from '@/singletons/issue.state.singleton';
import ZoneState from '@/singletons/zone.state.singleton';
import WeekState from '@/singletons/week.state.singleton';

/* Services */
import IssueService from '@/Services/Issue/issue.service';

/* Classes */
import { Issue, issueStatusColors } from '@/classes/issue/issue';
import CustomEvent from '@/classes/custom.event.class';

/* Mixins */
import QueryMixin from '../../../mixins/query.mixin';
import MapIssueController from '../../../classes/map/map.issue.controller';

export default {
  components: {
    Header,
    Card,
    IssueListItem,
    DropDownMenu,
  },
  mixins: [QueryMixin],
  data() {
    return {
      IssueState,
      WeekState,
      issueStatusColors,
      statusAlternatives: [
        {
          key: 'OPEN',
          title: this.$t('Components.Admin.Issue_Reports.issue_opened'),
          icon: '/icons/circle.svg',
          style: { fill: issueStatusColors.OPEN },
        },
        {
          key: 'IN_PROGRESS',
          title: this.$t('Components.Admin.Issue_Reports.issue_in_progress'),
          icon: '/icons/circle.svg',
          style: { fill: issueStatusColors.IN_PROGRESS },
        },
        {
          key: 'RESOLVED',
          title: this.$t('Components.Admin.Issue_Reports.issue_resolved'),
          icon: '/icons/circle.svg',
          style: { fill: issueStatusColors.RESOLVED },
        },
        {
          key: 'CLOSED',
          title: this.$t('Components.Admin.Issue_Reports.issue_closed'),
          icon: '/icons/circle.svg',
          style: { fill: issueStatusColors.CLOSED },
        },
      ],
    };
  },
  computed: {
    issue() {
      const issueid = Number(this.$route.params.issueid);
      return IssueState.issues[issueid] || IssueState.pinnedIssues[issueid];
    },
    pinIconSrc() {
      if (this.issue?.pinned) {
        return '/icons/pin.svg';
      }
      return '/icons/pin_outlined.svg';
    },
    resource() {
      return ZoneState.activeResources.find((zone) => zone.Zid === this.issue?.zoneid);
    },
    selected() {
      return this.statusAlternatives.find((dropdownAlternative) => dropdownAlternative.key === this.issue?.status);
    },
    availableStatusList() {
      return this.statusAlternatives.filter((status) => status.key !== this.issue?.status);
    },
  },
  mounted() {
    // We do this since the datepicker decorates the URL with date when we refresh the page
    this.removeQuery('date');
  },
  methods: {
    async onDropdownItemClicked(item) {
      this.issue.status = item.key;
      const updatedIssue = await IssueService.updateIssue(this.issue.id, new Issue({
        status: this.issue.status,
      }));

      MapIssueController.updateIssueMarker(this.issue.zoneid, { [this.issue.id]: updatedIssue });
      MapIssueController.selectIssueMarkerInZone(this.issue.zoneid);

      const evtIssueStatusChanged = new CustomEvent(this.$t('Components.Admin.Issue_Reports.issue_status_changed'), 'global_error_message', 'success');
      evtIssueStatusChanged.dispatch();
    },
    async togglePinnedIssue() {
      this.issue.pinned = !this.issue.pinned;
      const updatedIssue = await IssueService.updateIssue(this.issue.id, new Issue({
        pinned: this.issue.pinned,
      }));

      if (this.$parent.$data.viewingPinnedIssues) {
        MapIssueController.deselectIssueMarker();
        if (!updatedIssue.pinned) {
          MapIssueController.deleteIssueMarker(updatedIssue.zoneid);
        } else {
          MapIssueController.addIssueMarkers(IssueState.pinnedIssues, () => {
            MapIssueController.selectIssueMarkerInZone(this.issue.zoneid);
          });
        }
      } else {
        MapIssueController.updateIssueMarker(this.issue.zoneid, { [this.issue.id]: updatedIssue });
      }

      new CustomEvent(
        this.issue.pinned
          ? this.$t('Components.Admin.Issue_Reports.issue_pinned')
          : this.$t('Components.Admin.Issue_Reports.issue_un_pinned'),
        'global_error_message',
        'success',
      ).dispatch();
    },
  },
};
</script>

<style lang="scss">
  .header-actions-wrapper {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 3rem;
    .map-pin-icon, .issue-pin-icon {
      display: inline-block;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      cursor: pointer;
      svg {
        path {
          fill: white;
        }
      }
    }
  }

  .pinned-issues-icon {
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 4rem;
    svg {
      path {
        fill: white;
      }
    }
  }

  .issue-set-new-status {
    display: flex;
  }

  .status-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    h6 {
      margin: auto 0;
    }
  }

  .gen-dropdown-menu {
    border-radius: 1rem;
    min-width: 190px;

    .gen-dropdown-option {
      background: unset;
    }

    div.gen-dropdown-option {
      z-index: 20;
      border: 1px solid var(--border-color);
      border-radius: 0.5rem;
      background: var(--bg-color);
      .row-container {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
    }

    .gen-dropdown-content {
      z-index: 10;
      width: 100%;
      padding-top: 2rem;
      top: calc(100% - 2rem);
      border: 1px solid var(--border-color);
      border-radius: 0.5rem;
    }
  }
</style>
