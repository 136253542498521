<template>
  <div v-if="clickable">
    <div class="issue-type--container">
      <div
        v-for="(issueType, index) of issueTypeList"
        :key="index"
      >
        <label
          class="issue-type--tile margin"
          :class="issueType.Selected ? 'selected': null"
          @click="onIssueTypeClicked(issueType)"
        >
          <svg-icon
            :src="`/icons/eq-icons/${issueType.Icon || issueType.icon || 'eq-icon__default_resource'}.svg`"
            class="icon-size-medium"
          />
          {{ issueType.Title }}
        </label>
      </div>
    </div>
    <div v-if="!issueTypeList?.length">
      <p class="unselectable">
        {{ $t('Views.Resource.Issue.no_equipment') }}
      </p>
    </div>
  </div>
  <div v-else>
    <div class="issue-type--container">
      <div
        v-for="(issueType, index) in issueTypeList"
        :key="index + 'D'"
      >
        <label class="issue-type--tile-non-clickable margin">
          <svg-icon
            :src="`/icons/eq-icons/${issueType.icon || 'eq-icon__default_resource'}.svg`"
            class="icon-size-medium"
          />
          {{ issueType.type }}
        </label>
      </div>
    </div>
    <div v-if="!issueTypeList?.length">
      <p class="unselectable">
        {{ $t('Views.Resource.Issue.no_equipment') }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    zoneid: {
      type: Number,
      required: true,
    },
    issueTypeList: {
      type: Array,
      default: null,
      required: false,
    },
    clickable: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    onIssueTypeClicked(issueType) {
      this.$emit('issue-type-clicked', issueType);
    },
  },
};
</script>

<style lang="scss">
.issue-type--container {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.issue-type--tile {
  user-select: none;
  padding: 0.5rem;
  border-radius: 0.5rem;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);

  &.selected {
    background: var(--resource-pastel-color);
    border-color: var(--resource-pastel-color);
  }
  .icon-size-medium {
    svg {
      path {
        fill: var(--primary-color);
      }
    }
  }
}
.issue-type--tile-non-clickable {
  padding: 0.5rem;
  border-radius: 0.5rem;
  display: block;
  max-height: 50px;
  background-color: var(--pastel-primary);
  color: var(--primary-color);

  .icon-size-medium {
    svg {
      fill: var(--primary-color);
    }
  }
}
</style>
