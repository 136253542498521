<template>
  <!-- IF all the issues for a specific resource are displayed in a list -->
  <div
    v-if="isInListView"
    class="issue-list-item_wrapper"
  >
    <div class="margin margin-right small-padd">
      <RoundResourceIcon
        type="issue-icon"
        :src="connectedResource.Icon"
        :style="{ background: issuePastelColors[issue.status] }"
        :icon-style="{ fill: issueStatusColors[issue.status] }"
      />
    </div>
    <div
      class="info overflow-hidden margin margin-right margin-medium"
      @click="routeToSpecificIssue(issue.id)"
    >
      <p
        v-if="issue.id"
        class="normal-semi elipsis"
      >
        {{ connectedResource.Name }} - {{ connectedResource.TypeName }}
      </p>
      <div class="issue-author overflow-hidden">
        <p class="small-regular elipsis">
          {{ issue.authorname }}, {{ connectedResource.FloorName }}
        </p>
      </div>
    </div>
    <div class="end-icons padding padding-small padding-top">
      <div
        v-if="$route.name !== 'admin-resource-specific-issues'"
        @click="routeToResourceTiedIssues()"
      >
        <svg-icon
          class="end-icon"
          src="/icons/map_navigation_pin.svg"
        />
      </div>
    </div>
  </div>

  <!-- ELSE display the single issue as it was selected from the list view -->
  <div
    v-else
    class="issue-item-wrapper"
  >
    <div>
      <Card>
        <h6 class="padded-header">{{ $t('Views.Resource.Information.equipment') }}</h6>
        <IssueTypeList
          :zoneid="issue.zoneid"
          :issue-type-list="issue.types"
          :clickable="false"
        />
      </Card>
      <Card>
        <h6 class="padded-header normal-semi">
          {{ $t('Components.Admin.Issue_Reports.issue_description') }}
        </h6>
        <p class="issue-info-desc">
          {{ issue.description }}
        </p>
      </Card>
      <Card>
        <h6 class="padded-header normal-semi">
          {{ $t('Components.Admin.Issue_Reports.created_at') }}
        </h6>
        <p>
          {{ issueSubmittedAt }}
        </p>
      </Card>
      <Card>
        <div class="author-wrapper">
          <h6>
            {{ $t('Components.Admin.Issue_Reports.issue_author') }}
          </h6>
          <div class="issue-author-avatar-container">
            <div class="issue-author_container overflow-hidden">
              <h6>
                {{ issue.authorname }}
              </h6>
            </div>
            <div class="person-avatar">
              <PersonAvatar
                :src="avatar"
                height="50px"
                width="50px"
                :border="false"
              />
            </div>
          </div>
        </div>
      </Card>
    </div>
  </div>
</template>

<script>
/* States */
import ZoneState from '@/singletons/zone.state.singleton';
import WeekState from '@/singletons/week.state.singleton';
import IssueState from '@/singletons/issue.state.singleton';
import ColleagueState from '@/singletons/colleague.state.singleton';
import CompanyState from '@/singletons/company.state.singleton';
import UserState from '@/singletons/user.state.singleton';

/* Classes and mixins */
import QueryMixin from '@/mixins/query.mixin';
import ExtendedDate from '@/classes/extended.date.class';
import { issueStatusColors, issuePastelColors } from '@/classes/issue/issue';
import LocationController from '@/views/location.selection/location.selection.controller';

/* Components */
import RoundResourceIcon from '@/components/icons/round.resource.icon.vue';
import IssueTypeList from '@/components/issue/issue.type.list.vue';
import Card from '@/components/card/card.vue';

export default {
  components: {
    RoundResourceIcon,
    IssueTypeList,
    Card,
  },
  mixins: [QueryMixin],
  props: {
    issue: {
      type: Object,
      required: true,
    },
    zid: {
      type: Number,
      required: false,
      default: null,
    },
    isInListView: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      ZoneState,
      IssueState,
      WeekState,
      issueStatusColors,
      issuePastelColors,
      ColleagueState,
    };
  },
  computed: {
    connectedResource() {
      const storedZone = ZoneState.activeResources.find((zone) => zone.Zid === this.issue?.zoneid);
      const type = CompanyState.zoneTypes.find((_type) => _type.Constant === storedZone?.Type);
      if (!type) { return storedZone; }
      storedZone.Group = type.Group || '';
      storedZone.Icon = type.Icon;
      storedZone.TypeName = type.Name;
      storedZone.Constant = type.Constant;
      storedZone.FloorName = CompanyState.zones.find((z) => z.Zid === storedZone.ParentZone).Name;

      return storedZone;
    },
    avatar() {
      /* Get issue author's company information such as the avatar URL */
      let authorAvatarUrl = '/icons/invited_users_icon.svg';
      if (!this.isInListView) {
        const colleague = ColleagueState.colleagues.Users.find((employee) => employee.Username === this.issue.authoremail);
        if (colleague) {
          authorAvatarUrl = `${colleague.AvatarUrl}`;
        }
      }

      return authorAvatarUrl;
    },
    issueSubmittedAtIconSrc() {
      return '/icons/clock.svg';
    },
    issueSubmittedAt() {
      const date = new ExtendedDate(this.issue.createdat).getDateString();
      const time = new ExtendedDate(this.issue.createdat).localeTimeString();
      // const formatted = `${date} ${this.$t('Components.Admin.Issue_Reports.issue_time_info_at')} ${time}`;
      const formatted = `${date}, ${time}`;
      return formatted;
    },
  },
  methods: {
    switchFloorIfNeeded() {
      const zone = CompanyState.zones.find((z) => z.Zid === this.issue.zoneid);
      const floor = CompanyState.zones.find((z) => z.Zid === zone.ParentZone);
      const currentFloor = UserState.selectedLocation;
      if (floor.Zid !== currentFloor.Zid) {
        if (floor.Zid !== currentFloor.Zid) {
          LocationController.setLocationByFloorZid(floor.Zid);
        }
      }
    },
    async routeToResourceTiedIssues() {
      this.switchFloorIfNeeded();

      await this.$router.push({
        name: 'admin-resource-specific-issues',
        params: { zoneid: this.connectedResource.Zid },
        query: { issueid: this.issue.id },
      });
    },
    routeToSpecificIssue() {
      this.switchFloorIfNeeded();

      this.$router.replace({
        name: 'admin-specific-issue',
        params: { issueid: this.issue.id },
      });
    },
  },
};
</script>
<style lang="scss">
  .issue-info-container {
    display: flex;
    flex-grow: 1;
    cursor: pointer;
  }

  .issue-list-item_container{
    display: flex;
    justify-content: space-between;
  }

  .issue-status-icon {
    padding-top: 0.25rem;
    svg {
      path {
        fill: white;
      }
    }
  }

  .issue-info {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    font-size: 0.8rem;
    cursor: pointer;
    .issue-author {
      display: flex;
      flex-direction: row;
      svg {
        width: 1.2rem;
        height: 1.2rem;
        padding-bottom: 0.2rem;
        fill: var(--primary-color);
      }
    }
    h6 {
      line-height: 20px;
    }
    p {
      margin-bottom: 0;
    }
  }

  .end-icons {
    gap: 0.625rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    cursor: pointer;
    svg {
      fill: var(--primary-color);
    }
    .map-pin {
      margin-top: 0.6rem;
    }
  }

  .issue-author-avatar-container {
    display: flex;
    overflow: hidden;
    gap: 1rem;
    .issue-author_container{
      margin: auto 0;
    }
  }

  .round-resource-icon__wrapper.standard {
    border: solid 0px;
  }

  .normal-semi {
    margin-bottom: 0rem;
  }

  .issue-info-title, .issue-info-desc {
    margin-top: 0rem;
  }

  .issue-author-submission-info {
    display: flex;
    svg {
      width: 1.5rem;
      height: 1.5rem;
      margin-right: 0.3rem;
    }
    * {
      margin-top: auto;
      margin-bottom: auto;
    }
  }

 .issue-list-item_wrapper {
    display: flex;
    flex-grow: 1;
  }
  .small-padd {
    padding-top: 0.2rem;
  }
  .issue-list-item_wrapper.last-item {
    display: flex;
    flex-grow: 1;
    margin-bottom: 0rem;
  }
  .info {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    font-size: 0.8rem;
    cursor: pointer;
    .issue-author{
      display: flex;
      flex-direction: row;
      svg{
        width: 1.2rem;
        height: 1.2rem;
        padding-bottom: 0.2rem;
        fill: var(--primary-color);
      }
    }
    p {
      margin-bottom: 0;
    }
  }
  .end-icons {
    gap:1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    svg {
        fill: var(--primary-color);
    }
    .end-icon {
      cursor: pointer;
      svg {
        margin-right: 0;
      }
    }
  }
  .issue-item-wrapper {
    .padded-header {
      font-weight: 600;
      padding-bottom: 1rem;
    }

    .author-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 2rem;

      h6 {
        margin: auto 0;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
</style>
